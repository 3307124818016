@import 'colors.scss';
@import 'commons.scss';

html{
    min-height: 100%;
    margin: 0;
    padding: 0;
}

body{
    background-color: #e7ecee;
    min-height: 100%;
    margin: 0;
    padding: 0;
}

*{
    // font-family: 'Open Sans', sans-serif;
    font-family: 'Roboto', sans-serif;
}

.app {
    &.wrapper{
        margin-left: 180px;
        padding-right: 8px;
        padding-left: 8px;
        padding-top: 75px;
    }
}

@media only screen and (max-width: 768px) {

    .app.wrapper{
        margin-left: 0 !important;
    }

}